import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      "turbo:submit-end",
      this.handleSubmit.bind(this)
    );
  }

  handleSubmit(event) {
    const [response] = event.detail;
    if (response.ok) {
      // Close the modal
      toggleModal("newTagModal");

      // Refresh the page to update the tags list
      window.location.reload();
    } else {
      // Handle error if needed
      console.error("Failed to create tag");
    }
  }
}
