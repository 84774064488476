import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["type", "amount", "rate"];
  static values = {
    propertyId: String,
  };

  connect() {
    if (this.typeTarget.value) {
      this.calculateTax();
    }
  }

  async calculateTax() {
    const taxType = this.typeTarget.value;
    if (!taxType) return;

    try {
      const response = await fetch(
        `/landlord/properties/${this.propertyIdValue}/taxes/calculate?tax_type=${taxType}`,
        {
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();

      if (data.success) {
        this.amountTarget.value = data.amount;
        if (this.hasRateTarget) {
          this.rateTarget.textContent = `${data.rate}%`;
        }
      } else {
        console.error("Error calculating tax:", data.error);
      }
    } catch (error) {
      console.error("Error calculating tax:", error);
    }
  }
}
