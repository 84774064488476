import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    // Auto dismiss after 5 seconds
    setTimeout(() => {
      this.dismiss();
    }, 5000);
  }

  dismiss() {
    this.element.classList.add("opacity-0", "transform", "translate-y-1");
    setTimeout(() => {
      this.element.remove();
    }, 300);
  }
}
