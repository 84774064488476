import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "openButton", "closeButton"];

  connect() {
    // Close menu when clicking outside
    document.addEventListener("click", (event) => {
      if (!this.element.contains(event.target)) {
        this.closeMenu();
      }
    });
  }

  toggle() {
    const menu = document.querySelector(".mobile-menu");
    menu.classList.toggle("hidden");
  }

  closeMenu() {
    const menu = document.querySelector(".mobile-menu");
    menu.classList.add("hidden");
  }
}
